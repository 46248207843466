const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('../pages/SchedulePage.vue') },
      { path: '/schedule', component: () => import('../pages/SchedulePage.vue') },
      { path: '/leaderboard', component: () => import('../pages/LeaderboardPage.vue') },
      {
        path: '/:catchAll(.*)*',
        component: () => import('../pages/ErrorNotFound.vue'),
      }
    ]
  },
  
]

export default routes